window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 166 || document.documentElement.scrollTop > 166) {
    document.querySelector(".nav-scroll").style.top = "0";
  } else {
    document.querySelector(".nav-scroll").style.top = "-166px";
  }
}

function mobileButton() {
  var x = document.querySelector(".mobile-container");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}